import BillingSession from '../components/Settings/BillingSession'
import React from 'react'
import Pagewrapper from '../components/PageWrapper'

export default function () {
    return (
        <Pagewrapper>
            <BillingSession />
        </Pagewrapper>
    )
}

