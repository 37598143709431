import { Modal, Result } from 'antd'
import React, { useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { httpGet, httpPost } from '../../services/http'
import { SyncOutlined } from '@ant-design/icons'
import { Session } from '../../types/session'
import { loadStripe } from '@stripe/stripe-js'
import { CustomerPortal } from '../../lib/interfaces'

const api_key = process.env.GATSBY_STRIPE_PUBLIC_KEY || ''

const StartCheckoutPage = () => {
    let location = useLocation()

    const manageBilling = async () => {
        try {
            const res = await httpPost<CustomerPortal>('/auth/customer-portal')
            if (!res.data) return
            if (res.data.url && typeof window !== 'undefined') {
                window.location.href = res.data.url
            } else if (res.data.sessionId) {
                const sessId = res.data.sessionId
                const stripe = await loadStripe(api_key)
                const stripeRes = await stripe?.redirectToCheckout({
                    sessionId: sessId,
                })
                Modal.error({
                    title: 'Could not complete checkout',
                    content: stripeRes?.error.message,
                })
            }
        } catch (error) {
            if (error.status === 401) {
                httpPost('/auth/send-verification-email')
                Modal.error({
                    title: 'You need to verify your E-mail',
                    content: 'You need a verified E-mail before you can start your subscription.',
                    onOk: () => {
                        navigate('/app')
                    },
                })
            }
        }
    }

    useEffect(() => {
        httpGet<Session>('/auth/user')
            .then((res) => {
                if (res.data.isLoggedIn) {
                    manageBilling()
                } else {
                    Modal.info({
                        title: 'Not logged in',
                        content: 'You have to be logged in, in order to continue',
                        okText: 'Log In',
                        onOk: () => {
                            navigate(
                                `/login/?redirect_url=${location.pathname}${location.search}`,
                                { replace: true }
                            )
                        },
                    })
                }
            })
            .catch((error) => {
                navigate('/error/', { replace: true })
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <div className="login-background">
            <div className="login-background-inner">
                <div className="login-container">
                    <div>
                        <Link to="/" replace>
                            <div className="logoImgLarge" />
                        </Link>
                        <Result icon={<SyncOutlined spin />} title="Redirecting to billing.." />
                    </div>
                </div>
                <br />
            </div>
        </div>
    )
}

export default StartCheckoutPage
